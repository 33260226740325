<template>
    <v-container>
        <h3 class="grey--text text--darken-2"> Choix et Options </h3>

        <p class="subtitle-1 grey--text text--darken-2 mb-3">
            Visualisez ici le détail et le suivi de vos différents choix et options relatifs à votre acquisition du lot
            <v-chip color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.product.reference }}</v-chip>
            sur le programme
            <v-chip color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.program.title }}</v-chip>.
        </p>

        <p class="subtitle-1 grey--text text--darken-2">
            <b>
                Vous avez jusqu'au 15/01/2022 pour finaliser vos choix et options.
            </b>
        </p>

        <v-tabs class="mb-2 mt-4 rounded" v-model="tab">
            <v-tab> Vos choix </v-tab>
            <v-tab> Vos options payantes </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" style="background: transparent">
            <v-tab-item>

                <p class="caption grey--text text--darken-2 mb-2">
                    Pour les différentes pièces composant votre acquisition, spécifiez vos choix parmi les différents possibilités qui vous sont proposées.
                </p>

                <v-row>
                    <v-col cols="12" lg="5" class="text-center">
                        <v-expansion-panels v-model="selectedRoomIndex" mandatory>
                            <v-expansion-panel v-for="room in tma.rooms" :key="room.name">
                                <v-expansion-panel-header>
                                    <span>
                                        <template v-if="isRoomDone(room)">
                                            <v-icon class="mr-2" color="green" small> fas fa-check </v-icon>
                                        </template>
                                        <template v-else>
                                            <v-icon class="mr-2" color="grey lighten-2" small> fas fa-check </v-icon>
                                        </template>
                                        {{ room.name }}
                                    </span>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <v-autocomplete v-for="option of room.options" v-model="option.value" class="mb-2" :key="option.name" :label="option.name" :items="option.choices" hide-details clearable outlined dense>
                                        <template v-slot:append-outer>
                                            <v-icon v-if="!option.value" color="red" small> fas fa-exclamation-circle </v-icon>
                                            <v-icon v-else color="white" small> fas fa-info-circle </v-icon>
                                        </template>

                                        <template v-slot:selection="data">
                                            <v-chip v-if="data.index === 0" small label>
                                                <v-avatar left>
                                                    <v-img :src="data.item.img" />
                                                </v-avatar>

                                                <span>
                                                    {{ data.item.name }}
                                                </span>
                                            </v-chip>

                                            <span v-if="data.index === 1" class="grey--text text-caption">
                                                (+{{ 5 - 1 }} autres)
                                            </span>
                                        </template>

                                        <template v-slot:item="{ item }">
                                            <v-list-item-avatar>
                                                <v-img :src="item.img" />
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <span>
                                                        {{ item.name }}
                                                    </span>
                                                </v-list-item-title>

                                                <v-list-item-subtitle v-if="item.additionalCost > 0">
                                                    <v-chip color="grey" label small dark> + {{ item.additionalCost | toCurrencyString() }} </v-chip>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <v-btn class="app-blue mt-5 white--text" :disabled="!allOptionsDone" large> Valider mes choix </v-btn>
                    </v-col>

                    <v-col cols="12" lg="7">
                        <v-card class="pa-2" outlined>
                            <h2 class="grey--text text--darken-2">
                                Récapitulatif {{ tma.rooms[selectedRoomIndex].name }}
                            </h2>

                            <v-divider class="mb-2" />

                            <v-row no-gutters>
                                <v-col cols="12" v-for="option of selectedRoomOptions" :key="option.name">
                                    <h3 class="grey--text text--darken-1"> {{ option.name }} </h3>
                                    <v-row>
                                        <v-col cols="3">
                                            <v-img :src="option.value.img" />

                                        </v-col>

                                        <v-col>
                                            <span class="grey--text text--darken-1">
                                                {{ option.value.name }}

                                            </span>

                                            <template v-if="option.value.additionalCost > 0">
                                                <v-chip color="grey" label dark> + {{ option.value.additionalCost | toCurrencyString() }} </v-chip>
                                            </template>
                                            <template v-else>
                                                <v-chip color="grey" label dark x-small> inclus </v-chip>
                                            </template>                                                
                                            <p v-html="option.value.description">

                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>

            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        Pas d'option payante pour ce programme
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
export default {
    name: 'Tma',

    data: () => ({
        tab: 0,
        selectedRoomIndex: 0,

        tma: {
            rooms: [
                {
                    name: 'Salon',
                    done: true,
                    options: [
                        {
                            name: 'Chauffage électrique',
                            value: null,
                            choices: [
                                { name: 'Radiateur électrique à inertie sèche 1000W', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/chauffage_1.jpg', description: 'Ce radiateur électrique est équipé d\'un cœur de chauffe en réfractite. Le radiateur diffuse une chaleur douce qui dure longtemps et vous aide à conserver la bonne température dans votre foyer. Il est parfait pour les pièces à vivre et les cuisines de 10m2<br>Surface de chauffe: 10m²<br>Forme du produit: Horizontale<br>l. 59,2 x H. 58,9 x P. 11,2 cm' },                                
                                { name: 'Radiateur connecté électrique double cœur de chauffe 1500W', additionalCost: 340, img: 'https://tools.api.immotalk.com/img/tma/chauffage_2.jpg', description: 'Rendez votre salon plus confortable et votre vie aussi facile que possible avec ce radiateur connecté 1500 W.<br><br>Idéal pour les pièces à vivre et les cuisines jusqu\'à 15 m², sa technologie à double coeur de chauffe combine un élément chauffant en fonte et un film chauffant en aluminium. Le film chauffant permet une augmentation rapide de la température et l\'élément en fonte diffuse cette chaleur doucement pendant longtemps. Grâce à son thermostat connecté exclusif, contrôlez et faites des économies d\'énergie. Son design plat et élégant s’adaptera à tous les intérieurs contemporains.' }
                            ]
                        },
                        {
                            name: 'Revêtement de sol',
                            value: null,
                            choices: [
                                { name: 'Carrelage poli aspect marbre gris Supreme Grey 60x120 cm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/carrelage_marbre.jpg', description: 'Un carrelage sol et mur poli aspect marbre gris ultra-brillant avec de magnifiques veines claires qui donnera à vos pièces un côté élégant et distingué.<br><br>- Poli et brillant<br>- Aspect marbre<br>- Grès cérame<br>- Facile d\'entretien<br><br>Avoir un sol en marbre est assurément un plus, chic et noble à la fois notre carrelage Supreme Grey présente des couleurs grises et blanches accompagnées de leurs finitions poli et brillant, un charme intemporel dans un format 60x120 cm facile à poser. Ce carrelage à l\'aspect de marbre très ressemblant qui sublimera vos espaces en leur apportant la modernité intemporelle d\'un marbre en grès cérame aux bords rectifiés. Le marbre a le don d\'apporter une touche de modernité et d\'élégance à vos intérieurs, il séduira vos proches et votre famille par son élégance assurée ! Synonyme de charme et de splendeur, le carrelage aspect marbre profite de toutes les qualités de cette pierre noble dont il est imité. En effet, ce type de revêtement tendance est à la fois élégant et résistant. Il est, par ailleurs, plus facile d’entretien et surtout, proposé à un tarif plus avantageux. Vous cherchez le revêtement parfait pour habiller vos murs et sol intérieurs ? Optez sans hésiter pour le carrelage poli aspect marbre gris Supreme Grey 60x120 cm. Il est réalisé en grès cérame porcelainé aux bords rectifiés avec des couleurs grises et blanches pour ajouter de l’authenticité à vos pièces. Ce carrelage brillant bénéficie, en plus, d’une finition polie pour un rendu encore plus séduisant. Ce carrelage pour sol et mur est fabriqué dans le respect de la norme CE, gage de sa qualité. Il est compatible avec les pièces humides et le sol chauffant.' },
                                { name: 'Carrelage aspect béton Lunare Cenere 60x60 cm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/carrelage_beton.jpg', description: 'Un carrelage en grès cérame et rectifié. Conçu pour que les espaces classiques ou modernes se fassent plus accueillants. La matière est pure, minimaliste et se distingue par une surface qui conserve un aspect naturel et offre un toucher sensuel.<br><br>- Aspect béton<br>- Bords rectifiés (possibilité de micro-joints)<br>- Trafic intense<br>- Facile d\'entretien<br><br>Le carrelage grès cérame : la qualité et l\'élégance Appelé en anglais stoneware, littéralement "objet de pierre", le grès est un matériau céramique, caractérisé par sa dureté incomparable ainsi que sa résistance impressionnante aux agressions chimiques et climatiques. Maitrisé en Chine dès le 10ème siècle, importé en Occident au 14ème, le grès, est depuis couramment utilisé dans le bâtiment, car plus noble que la simple terre cuite, mais aussi plus durable que les matières plastiques ou la fonte. Obtenu à partir d\'un mélange d\'argiles maigres frittées, puis cuit au four - entre 1200 et 1400°c - jusqu\'à atteindre un état de vitrification le rendant non poreux et imperméable, le grès porcelainé est le matériau céramique qui a le plus bas niveau d\'absorption d\'eau et une forte résistance à la flexion. Pressé et coloré dans la masse, il est nettement plus solide et dur que le grès cérame émaillé. L\'une de ses autres caractéristiques, est également d\'être particulièrement résistant à l\'abrasion, soit l\'inaltérabilité de sa surface au contact d\'objet ou de matériaux en mouvement.' },
                                { name: 'Carrelage aspect béton Lunare Cenere 30x60 cm', additionalCost: 500, img: 'https://tools.api.immotalk.com/img/tma/carrelage_beton.jpg', description: 'Un carrelage en grès cérame et rectifié. Conçu pour que les espaces classiques ou modernes se fassent plus accueillants. La matière est pure, minimaliste et se distingue par une surface qui conserve un aspect naturel et offre un toucher sensuel.<br><br>- Aspect béton<br>- Bords rectifiés (possibilité de micro-joints)<br>- Trafic intense<br>- Facile d\'entretien<br><br>Le carrelage grès cérame : la qualité et l\'élégance Appelé en anglais stoneware, littéralement "objet de pierre", le grès est un matériau céramique, caractérisé par sa dureté incomparable ainsi que sa résistance impressionnante aux agressions chimiques et climatiques. Maitrisé en Chine dès le 10ème siècle, importé en Occident au 14ème, le grès, est depuis couramment utilisé dans le bâtiment, car plus noble que la simple terre cuite, mais aussi plus durable que les matières plastiques ou la fonte. Obtenu à partir d\'un mélange d\'argiles maigres frittées, puis cuit au four - entre 1200 et 1400°c - jusqu\'à atteindre un état de vitrification le rendant non poreux et imperméable, le grès porcelainé est le matériau céramique qui a le plus bas niveau d\'absorption d\'eau et une forte résistance à la flexion. Pressé et coloré dans la masse, il est nettement plus solide et dur que le grès cérame émaillé. L\'une de ses autres caractéristiques, est également d\'être particulièrement résistant à l\'abrasion, soit l\'inaltérabilité de sa surface au contact d\'objet ou de matériaux en mouvement.'},
                                { name: 'Parquet Massif Chêne huilé 15x120 mm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/parquet_huile.jpg', description: 'Un parquet massif en chêne huilé blanchi, pour une déco aux accents pastels. Pour le plaisir d\'un sol massif authentique et élégant avec des lames robustes de largeur moyenne.<br><br>- Rainure languette<br>- Pose collée facile<br>- Finition huilé et blanchi<br>- Surface brossée<br>- Micro chanfreins sur tous les côtés<br>- Facile d\'entretien'},
                                { name: 'Parquet Contrecollé Chêne Bois Brut vernis mat 14x190 mm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/parquet_colle.jpg', description: 'Un parquet en chêne élégant avec pose flottante clipsable. Un parquet contrecollé en chêne 3 plis et agréable au toucher.<br><br>- Pose flottante Clipsable<br>- Vernis mat<br>- Légèrement brossé<br>- 4 micro chanfreins<br>- Facile d\'entretien<br><br>Un parquet contrecollé facile à poser La gamme Liverpool, composée de parquets élégants de 7 couleurs différentes, ravira les bricoleurs avec sa pose flottante clipsable grâce à son système Clic. Les quelques nœuds visibles dans les lames, donnent du caractère à ce bois qui procurera un charme sans pareil à vos pièces préférées. En 3 plis, avec une couche d\'usure de 3,5 mm recouverte d\'un magnifique vernis mat, ce parquet contrecollé est facile à entretenir, robuste, solide et agréable au toucher.'}                                
                            ]
                        }
                    ]
                },
                {
                    name: 'Chambre',
                    done: true,
                    options: [
                        {
                            name: 'Revêtement de sol',
                            value: null,
                            choices: [
                                { name: 'Parquet Massif Chêne huilé 15x120 mm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/parquet_huile.jpg', description: 'Un parquet massif en chêne huilé blanchi, pour une déco aux accents pastels. Pour le plaisir d\'un sol massif authentique et élégant avec des lames robustes de largeur moyenne.<br><br>- Rainure languette<br>- Pose collée facile<br>- Finition huilé et blanchi<br>- Surface brossée<br>- Micro chanfreins sur tous les côtés<br>- Facile d\'entretien'},
                                { name: 'Parquet Contrecollé Chêne Bois Brut vernis mat 14x190 mm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/parquet_colle.jpg', description: 'Un parquet en chêne élégant avec pose flottante clipsable. Un parquet contrecollé en chêne 3 plis et agréable au toucher.<br><br>- Pose flottante Clipsable<br>- Vernis mat<br>- Légèrement brossé<br>- 4 micro chanfreins<br>- Facile d\'entretien<br><br>Un parquet contrecollé facile à poser La gamme Liverpool, composée de parquets élégants de 7 couleurs différentes, ravira les bricoleurs avec sa pose flottante clipsable grâce à son système Clic. Les quelques nœuds visibles dans les lames, donnent du caractère à ce bois qui procurera un charme sans pareil à vos pièces préférées. En 3 plis, avec une couche d\'usure de 3,5 mm recouverte d\'un magnifique vernis mat, ce parquet contrecollé est facile à entretenir, robuste, solide et agréable au toucher.'}                                
                            ]
                        }
                    ]
                },
                {
                    name: 'Cuisine',
                    done: false,
                    options: [
                        {
                            name: 'Revêtement de sol',
                            value: null,
                            choices: [
                                { name: 'Carrelage poli aspect marbre gris Supreme Grey 60x120 cm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/carrelage_marbre.jpg', description: 'Un carrelage sol et mur poli aspect marbre gris ultra-brillant avec de magnifiques veines claires qui donnera à vos pièces un côté élégant et distingué.<br><br>- Poli et brillant<br>- Aspect marbre<br>- Grès cérame<br>- Facile d\'entretien<br><br>Avoir un sol en marbre est assurément un plus, chic et noble à la fois notre carrelage Supreme Grey présente des couleurs grises et blanches accompagnées de leurs finitions poli et brillant, un charme intemporel dans un format 60x120 cm facile à poser. Ce carrelage à l\'aspect de marbre très ressemblant qui sublimera vos espaces en leur apportant la modernité intemporelle d\'un marbre en grès cérame aux bords rectifiés. Le marbre a le don d\'apporter une touche de modernité et d\'élégance à vos intérieurs, il séduira vos proches et votre famille par son élégance assurée ! Synonyme de charme et de splendeur, le carrelage aspect marbre profite de toutes les qualités de cette pierre noble dont il est imité. En effet, ce type de revêtement tendance est à la fois élégant et résistant. Il est, par ailleurs, plus facile d’entretien et surtout, proposé à un tarif plus avantageux. Vous cherchez le revêtement parfait pour habiller vos murs et sol intérieurs ? Optez sans hésiter pour le carrelage poli aspect marbre gris Supreme Grey 60x120 cm. Il est réalisé en grès cérame porcelainé aux bords rectifiés avec des couleurs grises et blanches pour ajouter de l’authenticité à vos pièces. Ce carrelage brillant bénéficie, en plus, d’une finition polie pour un rendu encore plus séduisant. Ce carrelage pour sol et mur est fabriqué dans le respect de la norme CE, gage de sa qualité. Il est compatible avec les pièces humides et le sol chauffant.' },
                                { name: 'Carrelage aspect béton Lunare Cenere 60x60 cm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/carrelage_beton.jpg', description: 'Un carrelage en grès cérame et rectifié. Conçu pour que les espaces classiques ou modernes se fassent plus accueillants. La matière est pure, minimaliste et se distingue par une surface qui conserve un aspect naturel et offre un toucher sensuel.<br><br>- Aspect béton<br>- Bords rectifiés (possibilité de micro-joints)<br>- Trafic intense<br>- Facile d\'entretien<br><br>Le carrelage grès cérame : la qualité et l\'élégance Appelé en anglais stoneware, littéralement "objet de pierre", le grès est un matériau céramique, caractérisé par sa dureté incomparable ainsi que sa résistance impressionnante aux agressions chimiques et climatiques. Maitrisé en Chine dès le 10ème siècle, importé en Occident au 14ème, le grès, est depuis couramment utilisé dans le bâtiment, car plus noble que la simple terre cuite, mais aussi plus durable que les matières plastiques ou la fonte. Obtenu à partir d\'un mélange d\'argiles maigres frittées, puis cuit au four - entre 1200 et 1400°c - jusqu\'à atteindre un état de vitrification le rendant non poreux et imperméable, le grès porcelainé est le matériau céramique qui a le plus bas niveau d\'absorption d\'eau et une forte résistance à la flexion. Pressé et coloré dans la masse, il est nettement plus solide et dur que le grès cérame émaillé. L\'une de ses autres caractéristiques, est également d\'être particulièrement résistant à l\'abrasion, soit l\'inaltérabilité de sa surface au contact d\'objet ou de matériaux en mouvement.' },
                                { name: 'Parquet Massif Chêne huilé 15x120 mm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/parquet_huile.jpg', description: 'Un parquet massif en chêne huilé blanchi, pour une déco aux accents pastels. Pour le plaisir d\'un sol massif authentique et élégant avec des lames robustes de largeur moyenne.<br><br>- Rainure languette<br>- Pose collée facile<br>- Finition huilé et blanchi<br>- Surface brossée<br>- Micro chanfreins sur tous les côtés<br>- Facile d\'entretien'},
                                { name: 'Parquet Contrecollé Chêne Bois Brut vernis mat 14x190 mm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/parquet_colle.jpg', description: 'Un parquet en chêne élégant avec pose flottante clipsable. Un parquet contrecollé en chêne 3 plis et agréable au toucher.<br><br>- Pose flottante Clipsable<br>- Vernis mat<br>- Légèrement brossé<br>- 4 micro chanfreins<br>- Facile d\'entretien<br><br>Un parquet contrecollé facile à poser La gamme Liverpool, composée de parquets élégants de 7 couleurs différentes, ravira les bricoleurs avec sa pose flottante clipsable grâce à son système Clic. Les quelques nœuds visibles dans les lames, donnent du caractère à ce bois qui procurera un charme sans pareil à vos pièces préférées. En 3 plis, avec une couche d\'usure de 3,5 mm recouverte d\'un magnifique vernis mat, ce parquet contrecollé est facile à entretenir, robuste, solide et agréable au toucher.'}                                
                            ]
                        },
                        {
                            name: 'Plan de travail',
                            value: null,
                            choices: [
                                { name: 'Plan de travail en stratifié effet pierre', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/plantravail_2.jpg', description: 'Plan de travail en stratifié effet pierre L. 244 x P. 62 x ép. 3,8 cm<br><br>Temp. max. de résistance à la chaleur : 100° C<br>Nos plans de travail sont conçus pour être fonctionnels et faciles à entretenir. Il existe un large éventail d\'options, permettant de l\'adapter à votre style, pour rappeler les portes et accessoires. Vous pouvez ainsi créer un espace à votre image<br>Notre plan de travail stratifié aspect pierre claire naturelle apporte de la texture à votre cuisine, sans les inconvénients associés à l\'entretien de la pierre naturelle, et tout en offrant un excellent rapport qualité-prix<br>L\'option parfaite pour une cuisine à faible entretien. Facilement nettoyable en essuyant<br>Conçus dans une perspective de qualité pour offrir des performances optimales, nos plans de travail sont vendus avec une garantie de 5 ans' },                                
                                { name: 'Plan de travail en stratifié aspect bois clair', additionalCost: 50, img: 'https://tools.api.immotalk.com/img/tma/plantravail_1.jpg', description: 'Plan de travail en stratifié aspect bois clair GoodHome Kala 300 cm x 62 cm x ép. 3.8 cm<br><br><br>Plan de travail conçu pour être fonctionnel et facile à entretenir. Il existe un large éventail d\'options, permettant de l\'adapter à votre style, pour rappeler les portes et accessoires. Vous pouvez ainsi créer un espace à votre image.<br><br>Conçus dans une perspective de qualité pour offrir des performances optimales, tous nos plans de travail possèdent une garantie de 25 ans<br>Nous avons passé des heures à concevoir nos plans de travail stratifiés pour que le résultat soit le plus proche possible du matériau réel, afin que vous puissiez bénéficier d\'un rendu réaliste à un meilleur rapport qualité-prix<br>L\'option parfaite pour une cuisine à faible entretien. Facilement lavable' }
                            ]
                        },
                        {
                            name: 'Evier',
                            value: null,
                            choices: [
                                { name: 'Évier à encastrer 1 cuve / égouttoir quartz noir', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/evier_1.jpg', description: 'Évier à encastrer 1 cuve et égouttoir en quartz noir + Mitigeur laiton noir<br><br>Évier réversible 1 cuve + égouttoir à encastrer avec une garantie de 15 ans (Bonde et vidage inclus)<br>Dimensions évier : L. 85 x l. 54,5 x H. 27 cm<br>Matière : Quartz<br>Compatible avec un caisson à partir de 55 cm<br>Cartouche céramique Ø 32 mm' },
                                { name: 'Évier en résine blanche mat 2 bacs à encastrer', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/evier_2.jpg', description: 'Evier à encastrer en Résine avec Couvercle pour le trou de robinetterie et clips de fixation<br><br>Résine<br>Meuble de 90 cm<br>Caractéristiques et avantages<br>Dimensions encastrement : (L) 110 cm x (l) 50 cm' }
                            ]
                        }
                    ]
                },
                {
                    name: 'Salle de bain',
                    done: true,
                    options: [
                        {
                            name: 'Meuble vasque',
                            value: null,
                            choices: [
                                { name: 'Meuble à suspendre 4 tiroirs 120 cm + plan double vasque en résine', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/vasque_2.jpg', description: 'Meuble sous vasque à suspendre Cooke & Lewis Voluto grège 4 tiroirs 120 cm<br>Matière : Panneau particules Mélaminé<br>Dimensions du produit (cm) : l. 120 x P. 50 x H. 55<br>4 tiroirs avec freins de fermeture<br><br>Plan double vasque en résine blanc Cooke & Lewis Voluto 123 cm<br><br>Résine<br>Dimensions du produit (cm) : l. 123 x P. 51,5 x Ep. 4.5' },
                                { name: 'Ensemble salle de bains 140 cm meuble sous-vasque + plan vasque résine', additionalCost: 450, img: 'https://tools.api.immotalk.com/img/tma/vasque_1.jpg', description: 'Meuble à poser Cooke & Lewis Harmon chêne massif 140 cm<br><br>Structure de meuble et pied en chêne massif (origine États-Unis), vernis naturel mat à base d\'eau<br>Façades, côtés dos intérieur, réalisés en panneau de fibres de moyenne densité (MDF), plaqué chêne<br>Dimensions : l. 140 x P. 50 x H. 84,5 cm<br><br>Meuble en chêne massif vernis naturel mat. Tiroir avec freins de coulisses et aménagement intérieur.<br><br>Plan vasque en résine décor pierre noire Cooke & Lewis Harmon 140 cm<br><br>Miroir éclairant droit Type d\'installation: A encastrer<br>Résine<br>Largeur : 140 cm - Profondeur : 50 cm - Hauteur : 6 cm' }
                            ]
                        },
                        {
                            name: 'Ensemble de douche',
                            value: null,
                            choices: [
                                { name: 'Colonne de douche thermostatique Grohe', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/douche_1.jpg', description: 'Colonne de douche avec pomme de tête, douchette, flexible, barre, mitigeur thermostatique<br><br>Débit de 5 L/min<br>Hauteur du produit : 160 cm<br>Pomme de tête Ø 16 cm en ABS<br><br>Le système est un véritable concentré de technologies de pointe GROHE. Réglez la température souhaitée avec GROHE TurboStat et l\'eau restera exactement comme vous l\'aimez, quelle que soit la fluctuation du débit d\'alimentation. La fonction GROHE SafeStop, simple d\'utilisation, limite la température de l\'eau à 38 °C maximum<br><br>Économie d’eau grâce au système GROHE EcoJoy : mousseur régulateur de débit<br>Douchette 2 jets en ABS chromé<br>Picots anticalcaires<br>Matière Barre : Laiton' },
                                { name: 'Colonne de douche thermostatique et encastrable argent', additionalCost: 200, img: 'https://tools.api.immotalk.com/img/tma/douche_2.jpg', description: 'Clapets anti-retour, filtres et robinets d\'arrêt intégrés<br>Embouts en laiton chromés avec connexion anti-torsion<br>Avec réducteur de débit démontable. - Finition chromée. - Picots détartrables<br>Résistant à une température de 50°C et de 40 bars<br>Coude de raccordement carré avec support fixe de douchette - Avec support de douchette intégré.<br><br>Colonne de douche encastrée thermostatique tout inclus chromée<br>Mitigeur thermostatique de douche encastrable<br>Deux fonctions avec inverseur supérieur. - Finition chromée. - Croisillons carrés en ABS finition chromée<br>Croisillons carrés en ABS finition chromée<br>Cartouche thermostatique avec bulbe de fabrication française, avec filtres<br>Butée à 38°C, afin de garantir la sécurité des enfants<br>Inverseur avec cartouche à disque céramique avec une position de fermeture<br>Deux alimentations latérales en femelle<br>Une sortie haute en mâle 3/4, pour alimenter la douche de tête et une sortie gauche ou droite (au choix) en mâle 3/4<br>Profondeur d\'encastrement (hors carrelage) nécessaire = +/- 7cm' }
                            ]
                        },
                        {
                            name: 'Revêtement de sol',
                            value: null,
                            choices: [
                                { name: 'Carrelage poli aspect marbre gris Supreme Grey 60x120 cm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/carrelage_marbre.jpg', description: 'Un carrelage sol et mur poli aspect marbre gris ultra-brillant avec de magnifiques veines claires qui donnera à vos pièces un côté élégant et distingué.<br><br>- Poli et brillant<br>- Aspect marbre<br>- Grès cérame<br>- Facile d\'entretien<br><br>Avoir un sol en marbre est assurément un plus, chic et noble à la fois notre carrelage Supreme Grey présente des couleurs grises et blanches accompagnées de leurs finitions poli et brillant, un charme intemporel dans un format 60x120 cm facile à poser. Ce carrelage à l\'aspect de marbre très ressemblant qui sublimera vos espaces en leur apportant la modernité intemporelle d\'un marbre en grès cérame aux bords rectifiés. Le marbre a le don d\'apporter une touche de modernité et d\'élégance à vos intérieurs, il séduira vos proches et votre famille par son élégance assurée ! Synonyme de charme et de splendeur, le carrelage aspect marbre profite de toutes les qualités de cette pierre noble dont il est imité. En effet, ce type de revêtement tendance est à la fois élégant et résistant. Il est, par ailleurs, plus facile d’entretien et surtout, proposé à un tarif plus avantageux. Vous cherchez le revêtement parfait pour habiller vos murs et sol intérieurs ? Optez sans hésiter pour le carrelage poli aspect marbre gris Supreme Grey 60x120 cm. Il est réalisé en grès cérame porcelainé aux bords rectifiés avec des couleurs grises et blanches pour ajouter de l’authenticité à vos pièces. Ce carrelage brillant bénéficie, en plus, d’une finition polie pour un rendu encore plus séduisant. Ce carrelage pour sol et mur est fabriqué dans le respect de la norme CE, gage de sa qualité. Il est compatible avec les pièces humides et le sol chauffant.' },
                                { name: 'Carrelage aspect béton Lunare Cenere 60x60 cm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/carrelage_beton.jpg', description: 'Un carrelage en grès cérame et rectifié. Conçu pour que les espaces classiques ou modernes se fassent plus accueillants. La matière est pure, minimaliste et se distingue par une surface qui conserve un aspect naturel et offre un toucher sensuel.<br><br>- Aspect béton<br>- Bords rectifiés (possibilité de micro-joints)<br>- Trafic intense<br>- Facile d\'entretien<br><br>Le carrelage grès cérame : la qualité et l\'élégance Appelé en anglais stoneware, littéralement "objet de pierre", le grès est un matériau céramique, caractérisé par sa dureté incomparable ainsi que sa résistance impressionnante aux agressions chimiques et climatiques. Maitrisé en Chine dès le 10ème siècle, importé en Occident au 14ème, le grès, est depuis couramment utilisé dans le bâtiment, car plus noble que la simple terre cuite, mais aussi plus durable que les matières plastiques ou la fonte. Obtenu à partir d\'un mélange d\'argiles maigres frittées, puis cuit au four - entre 1200 et 1400°c - jusqu\'à atteindre un état de vitrification le rendant non poreux et imperméable, le grès porcelainé est le matériau céramique qui a le plus bas niveau d\'absorption d\'eau et une forte résistance à la flexion. Pressé et coloré dans la masse, il est nettement plus solide et dur que le grès cérame émaillé. L\'une de ses autres caractéristiques, est également d\'être particulièrement résistant à l\'abrasion, soit l\'inaltérabilité de sa surface au contact d\'objet ou de matériaux en mouvement.' },
                                { name: 'Carrelage aspect béton Lunare Cenere 30x60 cm', additionalCost: 250, img: 'https://tools.api.immotalk.com/img/tma/carrelage_beton.jpg', description: 'Un carrelage en grès cérame et rectifié. Conçu pour que les espaces classiques ou modernes se fassent plus accueillants. La matière est pure, minimaliste et se distingue par une surface qui conserve un aspect naturel et offre un toucher sensuel.<br><br>- Aspect béton<br>- Bords rectifiés (possibilité de micro-joints)<br>- Trafic intense<br>- Facile d\'entretien<br><br>Le carrelage grès cérame : la qualité et l\'élégance Appelé en anglais stoneware, littéralement "objet de pierre", le grès est un matériau céramique, caractérisé par sa dureté incomparable ainsi que sa résistance impressionnante aux agressions chimiques et climatiques. Maitrisé en Chine dès le 10ème siècle, importé en Occident au 14ème, le grès, est depuis couramment utilisé dans le bâtiment, car plus noble que la simple terre cuite, mais aussi plus durable que les matières plastiques ou la fonte. Obtenu à partir d\'un mélange d\'argiles maigres frittées, puis cuit au four - entre 1200 et 1400°c - jusqu\'à atteindre un état de vitrification le rendant non poreux et imperméable, le grès porcelainé est le matériau céramique qui a le plus bas niveau d\'absorption d\'eau et une forte résistance à la flexion. Pressé et coloré dans la masse, il est nettement plus solide et dur que le grès cérame émaillé. L\'une de ses autres caractéristiques, est également d\'être particulièrement résistant à l\'abrasion, soit l\'inaltérabilité de sa surface au contact d\'objet ou de matériaux en mouvement.'}
                            ]
                        }
                    ]
                },
                {
                    name: 'WC',
                    done: true,
                    options: [
                        {
                            name: 'Type de WC',
                            value: null,
                            choices: [
                                { name: 'WC à poser sans bride, sortie horizontale Jacob Delafon Gaspard', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/wc_pose.png', description: 'Mécanisme 3/6 L<br>Abattant en thermodur<br>Cuvette sans bride : entretien facile' },
                                { name: 'WC suspendu sans bride, Collection Design Villeroy & Boch', additionalCost: 150, img: 'https://tools.api.immotalk.com/img/tma/wc_suspendu.png', description: 'Cuvette sans bride : entretien facilité et hygiène<br>Fermeture : abattant frein de chute, amovible<br>Bâti-support autoportant<br>Plaque de déclenchement<br>Economie d\'eau : rinçage haute performance, chasse 3 L /4,5 L<br>Siphon émaillé<br>Qualité certifié Villeroy & Boch<br>Design moderne : ligne épurée' }
                            ]
                        },
                        {
                            name: 'Revêtement de sol',
                            value: null,
                            choices: [
                                { name: 'Carrelage poli aspect marbre gris Supreme Grey 60x120 cm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/carrelage_marbre.jpg', description: 'Un carrelage sol et mur poli aspect marbre gris ultra-brillant avec de magnifiques veines claires qui donnera à vos pièces un côté élégant et distingué.<br><br>- Poli et brillant<br>- Aspect marbre<br>- Grès cérame<br>- Facile d\'entretien<br><br>Avoir un sol en marbre est assurément un plus, chic et noble à la fois notre carrelage Supreme Grey présente des couleurs grises et blanches accompagnées de leurs finitions poli et brillant, un charme intemporel dans un format 60x120 cm facile à poser. Ce carrelage à l\'aspect de marbre très ressemblant qui sublimera vos espaces en leur apportant la modernité intemporelle d\'un marbre en grès cérame aux bords rectifiés. Le marbre a le don d\'apporter une touche de modernité et d\'élégance à vos intérieurs, il séduira vos proches et votre famille par son élégance assurée ! Synonyme de charme et de splendeur, le carrelage aspect marbre profite de toutes les qualités de cette pierre noble dont il est imité. En effet, ce type de revêtement tendance est à la fois élégant et résistant. Il est, par ailleurs, plus facile d’entretien et surtout, proposé à un tarif plus avantageux. Vous cherchez le revêtement parfait pour habiller vos murs et sol intérieurs ? Optez sans hésiter pour le carrelage poli aspect marbre gris Supreme Grey 60x120 cm. Il est réalisé en grès cérame porcelainé aux bords rectifiés avec des couleurs grises et blanches pour ajouter de l’authenticité à vos pièces. Ce carrelage brillant bénéficie, en plus, d’une finition polie pour un rendu encore plus séduisant. Ce carrelage pour sol et mur est fabriqué dans le respect de la norme CE, gage de sa qualité. Il est compatible avec les pièces humides et le sol chauffant.' },
                                { name: 'Carrelage aspect béton Lunare Cenere 60x60 cm', additionalCost: 0, img: 'https://tools.api.immotalk.com/img/tma/carrelage_beton.jpg', description: 'Un carrelage en grès cérame et rectifié. Conçu pour que les espaces classiques ou modernes se fassent plus accueillants. La matière est pure, minimaliste et se distingue par une surface qui conserve un aspect naturel et offre un toucher sensuel.<br><br>- Aspect béton<br>- Bords rectifiés (possibilité de micro-joints)<br>- Trafic intense<br>- Facile d\'entretien<br><br>Le carrelage grès cérame : la qualité et l\'élégance Appelé en anglais stoneware, littéralement "objet de pierre", le grès est un matériau céramique, caractérisé par sa dureté incomparable ainsi que sa résistance impressionnante aux agressions chimiques et climatiques. Maitrisé en Chine dès le 10ème siècle, importé en Occident au 14ème, le grès, est depuis couramment utilisé dans le bâtiment, car plus noble que la simple terre cuite, mais aussi plus durable que les matières plastiques ou la fonte. Obtenu à partir d\'un mélange d\'argiles maigres frittées, puis cuit au four - entre 1200 et 1400°c - jusqu\'à atteindre un état de vitrification le rendant non poreux et imperméable, le grès porcelainé est le matériau céramique qui a le plus bas niveau d\'absorption d\'eau et une forte résistance à la flexion. Pressé et coloré dans la masse, il est nettement plus solide et dur que le grès cérame émaillé. L\'une de ses autres caractéristiques, est également d\'être particulièrement résistant à l\'abrasion, soit l\'inaltérabilité de sa surface au contact d\'objet ou de matériaux en mouvement.' }
                            ]
                        }
                    ]
                }
            ]
        }
    }),

    methods: {
        isRoomDone(room) {
            return room.options.filter((o) => o.value).length === room.options.length;
        }
    },

    computed: {
        selectedRoomOptions() {
            return this.tma.rooms[this.selectedRoomIndex].options.filter((o) => o.value);
        },

        allOptionsDone() {
            return this.tma.rooms.every((r) => r.options.every((o) => o.value));
        }
    }
};
</script>
